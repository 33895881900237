import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Can your UI UX design agency in Ahmedabad work across different industries?",
  "How can I request a proposal for my project?",
  "What core services can I expect from your web design company in Ahmedabad?",
  "How do you approach design projects at your web design company in Ahmedabad?",
  "What is the pricing model of your UI UX design agency in Ahmedabad?",
];

const AhmedabadPage = () => {
  const banner = {
    title: `UI UX Design Agency in Ahmedabad`,
    content: "We are a leading UI UX Design Agency in Ahmedabad. Our team of experienced and passionate designers has a proven track record of innovating and transforming digital solutions across diverse industries.",
      img: ["why-octet-ahmedabad.webp"],
      imageTitle: "UI UX Design Studio, Ahmedabad",
      imageAlt: "Octet Design Studio - A UI UX Company in Ahmedabad",
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "A Property Management Platform",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp"],
    },
    {
      title: "Survey2Connect",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      link: "/project/survey2connect/",
      firstAlt: "UI UX Design Screen to Create and Build Surveys",
      firstTitle: "Survey Builder UI Screen",
      secondAlt: "UI UX Design to add and customise Questions in Survey Builder",
      secondTitle: "UI Design to Create Questionnaire",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["survey.webp", "survey-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Yes, being the best website design company in Ahmedabad, we have the expertise you need to benefit your business industry and offer meticulous attention to projects.`,
        `We always follow certain phases to create exceptional designs that meet your specific requirements and are adaptable to various sectors, including healthcare, e-commerce, finance, SaaS, and more. `,
      ],
    },
    {
      para: [
        `If you want to use our expert UI UX services or hire UI UX designers, you can request a quote by filling out this <a href="/contact-us/" title="Contact Us">contact us form</a>. We will respond within a few hours to discuss your project's challenges and possible solutions.`,
      ],
    },
    {
      para: [
        `Octet is the leading UI UX design agency in Ahmedabad, and we are dedicated to creating user-centric designs because we understand that your product’s success depends on the user experience of your application.`,
        `We offer a comprehensive suite of UI UX services to meet your design and creative needs. Our core solutions include <a href="/design-audit/" title="design audit services">design audit</a>, <a href="/ux-consulting/" title="ux consultation services">ux consultation</a>, <a href="/rapid-prototyping/" title="rapid prototyping services">rapid prototyping</a>, <a href="/branding/" title="Branding Services">branding</a>, <a href="/angularjs-development/" title="Angular Development Services">angular development</a> and <a href="/services/" title="Our Design Services">more</a>.`,
      ],
    },
    {
      para: [
        `The design process we follow at our web design company in Ahmedabad is very flexible, allowing us to adapt to any industry, project type, and collaboration model.`,
        `However, we always follow certain phases to create exceptional designs that meet your specific requirements-`,
      ],
      list: [
        `<span><h4 class="inline"> Listening and Understanding</h4></span> - We start by understanding your product and the challenges you are facing to define the nature of our collaboration.`,
        `<span><h4 class="inline"> Identifying the Challenges</h4></span> - Based on our discussions, we start our research, explore and identify the market and gather data through various techniques.`,
        `<span><h4 class="inline"> UX Strategy and Information Architecture</h4></span> - Once we have all the data, we start by planning the UX flow, creating guidelines, and structuring information architecture, considering your business goals.`,
        `<span><h4 class="inline"> Sketching and Wire-Framing Process</h4></span> - We then produce visuals of your idea and create elements and a hierarchy to understand the functions and steps of your product.`,
        `<span><h4 class="inline"> UI Design, Prototype and Testing</h4></span> - Once the layout and designs are approved, we create a prototype to test the final product's functionality and interactivity.`,
      ],
    },
    {
      para: [
        `Our pricing is tailored to the specific needs and scope of each project. We understand that every project is unique, so we offer a flexible pricing model based on factors such as project complexity, size, and the required range of services.`,
        `To provide an accurate quote, we'd be happy to discuss your project in detail and understand your requirements.`,
        `Please feel free to <a href="/contact-us/" title="Contact Us" target="blank">reach out to us</a> for a personalized quote. We'll work closely with you to ensure our services align with your budget and project goals.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Work with the best <span class='h1-span'>Web Design Company in</span> Ahmedabad",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Boost Engagement with <span class='h1-span'>UI UX Design Company in</span> Ahmedabad",
    des: "We are more than a company; we are a team of the best UI UX designers in Ahmedabad passionate about creating efficient, creative solutions. ",
    items: [
      {
        title: "User Research",
        content:
          "The team at our UI UX design company in Ahmedabad uses various techniques to collect data and gain insights to create products that customers need. Our goal is to ensure that your product succeeds and exceeds your expectations.",
        link: "/user-research/",
        linkText: 'To achieve long-term benefits, our <a href="/user-research/" title="User Research Agency">User Research Agency</a> streamlines your resource-intensive processes.'
      },
      {
        title: "UI UX Designing",
        content:
          "We systematically create interactive, aesthetically pleasing interfaces that guarantee a seamless and straightforward user experience. Our team fuse originality and functionality to design creative & engaging user experiences.",
        link: "/ui-ux-designing/",
        linkText: 'Our <a href="/ui-ux-designing/" title="UI UX Design Agency">UI UX Design Agency</a> in Ahmedabad creates personalized solutions for your company.'
      },
      {
        title: "Usability Testing",
        content:
          "Our UI UX services make our products as user-friendly and accessible as possible. That's why we test your products using various tools and techniques. This allows us to identify areas for improvement and ensure consistency across all our products.",
        link: "/usability-testing/",
        linkText: 'To create an interface that is easy to use, our <a href="/usability-testing/" title="usability testing agency">usability testing company</a> monitors how you use it and spots problems.'
      },
      {
        title: "UI Development",
        content:
          "Our team of the best designers in Ahmedabad is dedicated to delivering exceptional user experiences. We ensure that every design is meticulously transformed into a template with captivating visual components fully integrated into the Java framework.",
        link: "/ui-development/",
        linkText: 'Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> uses its proficiency in multiple programming languages to create functional user interfaces.'
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We have compiled frequently asked questions to provide informative answers about our UI UX design agency in Ahmedabad, design process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "Octet is Ahmedabad's best UI UX design agency, transforming SaaS, Enterprise, and B2B products through a wise, proactive, and proficient approach. Our flexible, lean, and agile approach empowers businesses to embark on a revolutionary journey and realize their full potential. We deliver results beyond expectations with unmatched expertise and the latest industry best practices.",
    // "Our flexible, lean and agile UI UX process aims to empower businesses to move towards a transformative journey and unlock their true potential.",
  ];
  const knowAboutTitle = "Choose our <span class='h1-span'> Website Design Company</span> in Ahmedabad";
  const commonContent = {
    title: "Get Results with Our Web Design Agency in Ahmedabad",
    content: [
      {
        title: "Scalable Designs",
        text: "Our website design company in Ahmedabad designs interfaces that are both cutting-edge and future-ready to assist businesses in adapting seamlessly to users' evolving user preferences and market demands.",
      },
      {
        title: "User-Centric Approach",
        text: "We adopt a user-centric approach to product development and prioritize user needs. Our UI UX design agency in Ahmedabad customizes the product to meet the target market's expectations and ensure customer satisfaction.",
      },
      {
        title: "Cross-Platform Adaptability",
        text: "Octet prioritizes responsive designs that work seamlessly across all devices and screen sizes, enhancing user accessibility. We are your go-to UI UX design company in Ahmedabad, aiming to deliver an exceptional user experience tailored to your needs.",
      },
      {
        title: "Continuous Improvement",
        text: "Our design methodology is based on a continuous improvement process informed by extensive user feedback and market data. As one of the best UI UX design agencies, we ensure our designs align with user behavior and remain responsive.",
      }
    ],
  };
  const commonContent1 = {
    title: "Our Design Process",
    para: ['We are a team of the best UI UX designers in Ahmedabad. We are enthused about new ideas and help businesses develop outstanding identities through top-notch UI UX design services.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX design company in Ahmedabad carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our UI UX design agency in Ahmedabad then strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "We formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with a visually appealing, user-friendly interface that enhances the user experience and maximizes your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "Upon finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our UI UX design company in Ahmedabad to evaluate the final product, ensuring it meets the required standards and specifications.",
      }
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Can your UI UX design agency in Ahmedabad work across different industries?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, being the best website design company in Ahmedabad, we have the expertise you need to benefit your business industry and offer meticulous attention to projects.
            We always follow certain phases to create exceptional designs that meet your specific requirements and are adaptable to various sectors, including healthcare, e-commerce, finance, SaaS, and more."
                }
              },{
                "@type": "Question",
                "name": "How can I request a proposal for my project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "If you want to use our expertise or hire UI UX designers, you can request a quote by filling out this Contact Us Form. We will respond within a few hours to discuss your project's challenges and possible solutions."
                }
              },{
                "@type": "Question",
                "name": "What core services can I expect from your web design company in Ahmedabad?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Octet is the leading UI UX design agency in Ahmedabad, and we are dedicated to creating user-centric designs because we understand that your product’s success depends on the user experience of your application.
            We offer a comprehensive suite of UI UX design services to meet your design and creative needs. Our core solutions include user research, UI UX design, usability testing, UI development, design audit, UX consultation, rapid prototyping, branding, angular development, and more."
                }
              },{
                "@type": "Question",
                "name": "How do you approach design projects at your web design company in Ahmedabad?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The design process we follow at our web design company in Ahmedabad is very flexible, allowing us to adapt to any industry, project type, and collaboration model.
            However, we always follow certain phases to create exceptional designs that meet your specific requirements-
            Listening and Understanding
            We start by understanding your product and the challenges you are facing to define the nature of our collaboration.
            Identifying the Challenges
            Based on our discussions, we start our research, explore and identify the market, and gather data through various techniques.
            UX Strategy and Information Architecture
            Once we have all the data, we start by planning the UX flow, creating guidelines, and structuring information architecture, considering your business goals.
            Sketching and Wire-Framing Process
            We then produce visuals of your idea and create elements and a hierarchy to understand the functions and steps of your product.
            UI Design, Prototype, and Testing
            Once the layout and designs are approved, we create a prototype to test the final product's functionality and interactivity."
                }
              },{
                "@type": "Question",
                "name": "What is the pricing model of your UI UX design agency in Ahmedabad?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our pricing is tailored to the specific needs and scope of each project. We understand that every project is unique, so we offer a flexible pricing model based on factors such as project complexity, size, and the required range of services.
            To provide an accurate quote, we'd be happy to discuss your project in detail and understand your requirements.
            Please feel free to contact us for a personalized quote. We'll work closely with you to ensure our services align with your budget and project goals."
                }
              }]
            } 
          `}
        </script>
      </Helmet>
      <Layout>
        <Banner content={banner} page={"ahmedabad-home"} />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout des={knowAboutDes} title={knowAboutTitle} imageAlt={'Logo of UI UX Design Agency in Ahmedabad'} imageTitle={"UI UX Design Agency's Logo"} />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent data={commonContent1} mode={"light"} list='single' />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Explore our Success Stories
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default AhmedabadPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency in Ahmedabad - Octet"
    description="We are the best UI UX design agency in Ahmedabad, transforming business ideas into design excellence with our expertise and experience. Contact us now!"
  />
);
